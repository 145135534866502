import { render, staticRenderFns } from "./voucher.vue?vue&type=template&id=519cdba7&scoped=true&"
import script from "./voucher.vue?vue&type=script&lang=js&"
export * from "./voucher.vue?vue&type=script&lang=js&"
import style0 from "./voucher.vue?vue&type=style&index=0&id=519cdba7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519cdba7",
  null
  
)

export default component.exports